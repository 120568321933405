<template>
  <v-footer
    class="py-4"
    dark
    height="auto"
  >
    <v-container class="mx-auto">
      <table>
        <tr>
          <td>
            <strong style="font-size: 25px">my flower diary</strong>
          </td>
        </tr>
      </table>
    </v-container>
  </v-footer>
</template>

<script>
  export default {
    name: 'CoreFooter',
  }
</script>
